<template>
  <content-wrapper :title="labels.LBL_PRODUCTS">
    <template slot="actions">
      <el-button size="small" type="success" @click="handleAdd">
        <i class="el-icon-plus mr-1"></i>
        {{ labels.LBL_ADD }}
      </el-button>
    </template>
    <el-table
      v-loading="loading"
      :data="productList"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
      :empty-text="labels.LBL_NO_DATA"
    >
      <el-table-column prop="name" :label="labels.LBL_NAME" sortable>
      </el-table-column>

      <el-table-column align="right" width="300">
        <template slot="header" slot-scope="scope">
          <el-row type="flex">
            <el-input
              v-model="search"
              size="mini"
              :placeholder="labels.LBL_TYPE_2_SEARCH"
              class="ml-1"
            />
          </el-row>
        </template>

        <template slot-scope="scope">
          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            circle
            @click="handleEdit(scope.row)"
          >
          </el-button>

          <el-button
            v-if="scope.row.is_deletable"
            size="mini"
            type="danger"
            icon="el-icon-delete"
            circle
            @click="handleDelete(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="pagination"
      :current-page.sync="pagination.current_page"
      :total="pagination.total"
      :page-size="parseInt(pagination.per_page)"
      layout="total, prev, pager, next"
      class="my-2"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </content-wrapper>
</template>

<script>
  import _ from "lodash";
  import { labels } from "@/common";
  import { mapState } from "vuex";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";

  export default {
    name: "Products",

    components: {
      ContentWrapper,
    },

    data() {
      return {
        labels,

        search: "",
        params: {
          page: 1,
          limit: 5,
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        products: (state) => state.product.products,
      }),

      productList() {
        return this.products && this.products.data;
      },

      pagination() {
        return this.products && this.products.meta;
      },
    },

    watch: {
      search: _.debounce(function(nv) {
        this.handleSearch(nv);
      }, 500),

      "params.page": {
        immediate: true,
        handler(nv) {
          if (nv) this.loadProducts();
        },
      },
    },

    methods: {
      loadProducts() {
        this.$store.dispatch("product/getAllProducts", this.params);
      },

      handlePageChange(val) {
        this.params.page = val;
      },

      handleSearch(search) {
        this.$store.dispatch("product/getAllProducts", {
          ...this.params,
          search,
        });
      },

      handleAdd() {
        this.$router.push({ name: "Add Product" });
      },

      handleEdit({ id }) {
        this.$router.push({ name: "CMS Product", params: { productId: id } });
      },

      handleDelete({ id }) {
        this.$confirm(`${labels.CONF_DELETE}?`, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_YES,
          cancelButtonText: labels.CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        })
          .then(() => {
            this.$store.dispatch("product/deleteService", id);
            console.log(id);
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_DELETE,
            });
          });
      },
    },
  };
</script>

<style></style>
