<template>
  <div style="width: 100%">
    <bread-crumb :breadcrumb-items="breadcrumbsItems" />

    <div class="container default-padding">
      <div class="primary-heading text-center mt-8">
        {{ `Our ${labels.MENU_PRODUCTS}` }}
      </div>

      <div class="caption">
        {{ labels.PH_PRODUCTS }}
      </div>
    </div>

    <div v-if="!loading">
      <div v-if="products && products.length > 0">
        <div
          v-for="product in products"
          :key="product.id"
          :id="product.id"
          class="product"
          data-aos="fade-up"
        >
          <div class="product__detail container default-padding">
            <img
              v-if="product.image_url"
              :src="product.image_url"
              :alt="product.name"
              class="product__image"
            />

            <div class="product__title primary-heading">
              {{ product.name }}
            </div>

            <div class="paragraph" v-html="product.description"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container default-padding">
      {{ `${labels.LBL_LOADING}...` }}
    </div>

    <get-a-quote />

    <horizontal-client-slider />
  </div>
</template>

<script>
  import { labels } from "@/common";
  import { mapState } from "vuex";

  import BreadCrumb from "@/components/BreadCrumb.vue";
  import GetAQuote from "../components/GetAQuote.vue";
  import HorizontalClientSlider from "../components/HorizontalClientSlider.vue";

  export default {
    name: "Products",

    components: {
      BreadCrumb,
      GetAQuote,
      HorizontalClientSlider,
    },

    data() {
      return {
        labels,
        breadcrumbsItems: [{ name: "Products", path: "" }],
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        products: (state) => state.product.products.data,
      }),
    },

    created() {
      this.loadProducts();
    },

    methods: {
      loadProducts() {
        this.$store.dispatch("product/getAllProducts", this.params);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .product {
    padding: 3rem 0;

    min-height: 400px;

    &__image {
      height: 350px;
      width: 50%;
      margin-bottom: 1rem;
      object-fit: cover;
    }

    &__title {
      margin-top: -0.5rem;
      font-size: 1.625rem;
      color: $primary-color;
      text-transform: uppercase;
    }

    &:nth-child(even) {
      background: $background-color;
      .product__image {
        float: right;
        margin-left: 2rem;
      }
    }

    &:nth-child(odd) {
      .product__image {
        float: left;
        margin-right: 2rem;
      }
    }
  }

  .install-req {
    background: $background-color;
    padding: 2rem 0;
  }

  @media only screen and (max-device-width: 768px) {
    .product {
      &__title {
        text-align: center;
      }

      &__image {
        width: 100%;
        margin: 0;
      }
    }
  }
</style>
